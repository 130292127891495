.container {
  padding: 80px 160px;
  @media (max-width: 768px) {
    padding: 40px 80px;
  }
  @media (max-width: 480px) {
    padding: 40px 16px;
  }
  display: flex;
  width: 100%;
  gap: 32px;
  flex-direction: column;
  .section {
    display: flex;
    gap: 24px;
    flex-direction: row;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    flex: 1;
    > div {
      flex: 1;
    }
    h2 {
      color: #444;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 150% */
    }
  }
  .servicesContainer {
    display: flex;
    flex-direction: column;
    padding: 24px;
    align-items: flex-start;
    gap: var(--Sizing-M, 16px);
    align-self: stretch;
    border-radius: 24px;
    background: var(--white-black-white, #FFF);
    box-shadow: -8px -8px 24px 0px rgba(223, 211, 248, 0.15), 8px 8px 24px 0px rgba(18, 3, 32, 0.05);
  }
  .services {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px var(--Sizing-M, 16px);
    align-self: stretch;
    flex-wrap: wrap;
  }
  .service {
    @media (min-width: 768px) {
      border-radius: 24px;
      border: 2px solid #F4F4F4;
      background: var(--white-black-white, #FFF);
      color: #222;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      display: flex;
      height: 108px;
      padding: 24px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
      flex: 1 0 30%;
      max-width: 346.67px;
    }
    cursor: pointer;
    @media (max-width: 767px) {
      display: flex;
      padding: 8px var(--Sizing-M, 16px);
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      color: #444;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      border-radius: 8px;
      border: 1px solid #CCC;
    }
    img {
      width: 32px;
      height: 32px;
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
  .active {
    border-radius: 24px;
    background: var(--GR, linear-gradient(90deg, #8F00FF 0%, #1400FF 100%));
    box-shadow: 0px 8px 8px 0px rgba(103, 10, 193, 0.15);
    color: var(--white-black-white, #FFF);
    @media (max-width: 767px) {
      border-radius: 8px;
      background: #222;
      color: white;
    }
  }
}
.btnSection {
  justify-content: center;
}
